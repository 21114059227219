import { Box, BoxBasedComponentProps } from '../Box';

export type DrawerMenuProps = BoxBasedComponentProps<
  'div',
  {
    title?: string;
  }
>;

export const DrawerMenu = ({ children, title, ...props }: DrawerMenuProps) => (
  <Box
    py={3}
    px={3}
    position="relative"
    css={{
      '& + &': {
        borderTopWidth: '1px',
        borderTopStyle: 'solid',
      },
    }}
    borderColor="tertiary.500"
    {...props}
  >
    {title && (
      <Box
        as="span"
        display="block"
        textStyle="body-normal-medium"
        color="neutral.300"
        px={3}
        py={2}
      >
        {title}
      </Box>
    )}
    {children}
  </Box>
);

export const NpssDrawerMenu = ({
  children,
  title,
  ...props
}: DrawerMenuProps) => (
  <Box
    py={4}
    px={3}
    position="relative"
    css={{
      '& + &': {
        borderTopWidth: '1px',
        borderTopStyle: 'solid',
      },
    }}
    borderColor="tertiary.500"
    {...props}
  >
    {title && (
      <Box
        as="span"
        display="block"
        textStyle="body-normal-medium"
        color="neutral.300"
        px={3}
        pt={0}
        pb={4}
      >
        {title}
      </Box>
    )}
    {children}
  </Box>
);
