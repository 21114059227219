import { useState } from 'react';
import { Box, Button } from '@amzn/ring-ui-react-components';

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from '../Modal';
import { useIsDesktop } from 'src/shared/hooks';
import { ScheduledEvent } from 'src/utils';
import { useTranslation } from 'react-i18next';
import { callAllHandlers } from '@chakra-ui/utils';
import { ScheduleForLater } from 'src/shared/components/ScheduleForLater';

export interface EventCardReScheduleModalProps
  extends Omit<ModalProps, 'children'> {
  onSubmit: (id: string, scheduledFor: string) => void;
  event: ScheduledEvent;
}

export const EventCardReScheduleModal = ({
  isOpen,
  onClose,
  onSubmit,
  event,
}: EventCardReScheduleModalProps) => {
  const { t } = useTranslation('features.myPosts');

  const isDesktop = useIsDesktop();
  const cancelButtonVariant = isDesktop ? 'outline' : 'ghost';

  const [scheduledFor, setScheduledFor] = useState<string>(event.scheduled_for);

  const handleScheduleForLaterChange = (scheduledFor: string) => {
    setScheduledFor(scheduledFor);
  };

  const handleSubmit = callAllHandlers(
    () => onSubmit(event.id, scheduledFor),
    onClose,
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay>
        <ModalContent minHeight="750px">
          <ModalHeader justifyContent="center" py={4}>
            <Box textStyle="body-normal-medium">
              {t('reschedule-post-modal.title')}
            </Box>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody px={{ base: 6, m: 8 }} py={6}>
            <Box textStyle="heading-normal-medium" mb={2}>
              {t('reschedule-post-modal.title')}
            </Box>
            <Box mb={2} textStyle="body-large-light">
              {t('reschedule-post-modal.description')}
            </Box>
            <ScheduleForLater
              scheduledFor={scheduledFor}
              handleChange={handleScheduleForLaterChange}
            />
          </ModalBody>
          <ModalFooter
            px={6}
            py={4}
            flexDirection={{
              base: 'column-reverse',
              m: 'row',
            }}
            borderTop={{ m: 'none' }}
          >
            <Box
              as={Button}
              variant={cancelButtonVariant}
              onClick={onClose}
              mr={{ base: 0, m: 2 }}
              width={{ base: '100%', m: 200 }}
            >
              {t('general-modal.cancel')}
            </Box>
            <Box
              as={Button}
              onClick={handleSubmit}
              mb={{ base: 3, m: 0 }}
              width={{ base: '100%', m: 200 }}
            >
              {t('general-modal.save')}
            </Box>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};
