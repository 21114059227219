import { useEffect } from 'react';
import { useAuth } from 'src/context';
import { usePrivacyCookie } from 'src/hooks';
import { addEventProperties } from 'src/utils/heap';
import { useSpectrometer } from './useSpectrometer';
import { useConsentManager } from 'src/components/App/hooks/useConsentManager';

export const useThirdPartyAnalytics = () => {
  const { isPending, isAuthenticated, isNpssUser } = useAuth();
  const isHeapAllowed = usePrivacyCookie('analytics_heap');

  // calling useConsentManager is necessary or heap calls don't fire
  const isTrackingAccepted = useConsentManager();

  useEffect(() => {
    if (!isPending && isHeapAllowed) {
      addEventProperties(isAuthenticated, isNpssUser);
    }
  }, [
    isPending,
    isAuthenticated,
    isTrackingAccepted,
    isHeapAllowed,
    isNpssUser,
  ]);

  useSpectrometer();
};
