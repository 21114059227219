import { useCallback } from 'react';
import { trackEventWithHeap } from 'src/utils/heap';
import { useCopyLinkToClipboard } from 'src/shared/hooks';
import { CardFooter, CardFooterProps } from '../Card';
import { useEventCardState, useEventCardUpdate } from './context';
import {
  EventCardFooterCommentAction,
  EventCardFooterShareAction,
  EventCardFooterUpvoteAction,
  EventCardFooterDisabledCommentAction,
} from './EventCardFooterAction';
import { ItemType } from 'src/components/App/hooks';
import { useAuth } from 'src/context';

type EventCardFooterBaseProps = Omit<CardFooterProps, 'py' | 'px'> & {
  dispatchEventStreamClick?: (
    item: ItemType,
    tags?: string[],
    properties?: any,
  ) => Promise<void>;
  disableShare?: boolean;
};

export const EventCardFooterBase = ({
  children,
  disableShare,
  dispatchEventStreamClick,
  ...props
}: EventCardFooterBaseProps) => {
  const {
    event: { share_url },
  } = useEventCardState();

  const { isPending } = useAuth();

  const copyShareUrl = useCopyLinkToClipboard(share_url);

  const handleShare = useCallback(() => {
    copyShareUrl();
    trackEventWithHeap('Share Link Click');
    if (isPending === false && dispatchEventStreamClick !== undefined)
      dispatchEventStreamClick('nhWeb_sharePost', ['Neighbors Web'], {});
  }, [copyShareUrl, dispatchEventStreamClick, isPending]);

  return (
    <CardFooter {...props} px={4} py={{ _: 2, m: 3 }}>
      {children}
      {!disableShare && share_url && (
        <EventCardFooterShareAction onClick={handleShare} />
      )}
    </CardFooter>
  );
};

export interface EventCardFooterProps extends EventCardFooterBaseProps {
  isVoteLoading: boolean;
  onComment?: () => void;
  onDownvote?: () => void;
  onUpvote?: () => void;
  disableShare?: boolean;
  disableUpvote?: boolean;
}

export const EventCardFooter = ({
  isVoteLoading,
  disableUpvote,
  onUpvote,
  onDownvote,
  onComment,
  dispatchEventStreamClick,
  ...props
}: EventCardFooterProps) => {
  const {
    event: { has_upvoted, upvote_count, comment_count, is_commentable },
  } = useEventCardState();

  const { decrementVoteCount, incrementVoteCount, setHasUpvoted } =
    useEventCardUpdate();

  const handleUpvote = useCallback(() => {
    if (has_upvoted) {
      setHasUpvoted(false);
      decrementVoteCount();
      onDownvote?.();

      trackEventWithHeap('Unlike Post');
    } else {
      setHasUpvoted(true);
      incrementVoteCount();
      onUpvote?.();

      trackEventWithHeap('Like Post');
      if (dispatchEventStreamClick)
        dispatchEventStreamClick('nhWeb_helpfulButton', ['Neighbors Web'], {});
    }
  }, [
    has_upvoted,
    setHasUpvoted,
    incrementVoteCount,
    decrementVoteCount,
    onUpvote,
    onDownvote,
    dispatchEventStreamClick,
  ]);

  return (
    <EventCardFooterBase
      dispatchEventStreamClick={dispatchEventStreamClick}
      data-testid="event-card-footer"
      {...props}
    >
      {!disableUpvote && (
        <EventCardFooterUpvoteAction
          disabled={isVoteLoading}
          isActive={has_upvoted}
          onClick={handleUpvote}
        >
          {upvote_count}
        </EventCardFooterUpvoteAction>
      )}
      {is_commentable ? (
        <EventCardFooterCommentAction onClick={onComment}>
          {comment_count}
        </EventCardFooterCommentAction>
      ) : (
        <EventCardFooterDisabledCommentAction />
      )}
    </EventCardFooterBase>
  );
};
