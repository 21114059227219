import { forwardRef, ReactNode, useCallback } from 'react';
import { useToggle } from 'react-use';
import { trackEventWithHeap } from 'src/utils/heap';
import { Card, CardProps } from '../Card';
import { ConfirmationModal } from '../ConfirmationModal';
import { IncidentCategoriesModal } from '../IncidentCategories';
import {
  EventCardProvider,
  EventCardProviderProps,
  useEventCardState,
} from './context';
import { useEventDropdownOptions, UseEventDropdownOptionsProps } from './hooks';
import {
  useResolveModalOptions,
  UseResolveModalOptionsProps,
} from './hooks/useResolveModalOptions';
import {
  getViewFromRoutePath,
  useNeighborsWebClickEventStreamDispatcher,
} from 'src/components/App/hooks';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { EventCardReScheduleModal } from 'src/shared/components/EventCard/EventCardReScheduleModal';
import { ScheduledEvent } from 'src/utils';
import { EventCardUpdateScheduledPostModal } from 'src/shared/components/EventCard/EventCardUpdateScheduledPostModal';

interface EventCardBaseChildrenProps {
  dropdownOptions: ReturnType<typeof useEventDropdownOptions>;
  resolveModalOptions: ReturnType<typeof useResolveModalOptions>;
  toggleCTAModalIsOpen?: () => void;
}

interface EventCardBaseProps
  extends CardProps,
    Pick<
      UseEventDropdownOptionsProps,
      | 'onClickCategoryDefinitions'
      | 'onClickReport'
      | 'onClickHide'
      | 'onClickDelete'
      | 'onClickReSchedule'
      | 'onClickEditScheduledPost'
    >,
    Pick<UseResolveModalOptionsProps, 'onResolve' | 'onUnresolve'> {
  children: (props: EventCardBaseChildrenProps) => ReactNode;
  handleResolvePostClick?: () => void;
  handleReSchedulePostClick?: (id: string, scheduledFor: string) => void;
  handleEditScheduledPostClick?: (
    id: string,
    title: string,
    description: string,
  ) => void;
}

const EventCardBase = forwardRef<HTMLDivElement, EventCardBaseProps>(
  (
    {
      children,
      onClickCategoryDefinitions,
      onClickReport,
      onClickHide,
      onClickDelete,
      onResolve,
      onUnresolve,
      onClickReSchedule,
      onClickEditScheduledPost,
      handleResolvePostClick,
      handleEditScheduledPostClick,
      ...props
    },
    ref,
  ) => {
    const {
      event: { is_owned, owner, category, is_resolved, resolved_message },
    } = useEventCardState();
    const agency_id = owner ? owner.agency_id : undefined;
    const resolveModalOptions = useResolveModalOptions({
      is_resolved,
      resolved_message,
      onResolve,
      onUnresolve,
    });

    const dropdownOptions = useEventDropdownOptions({
      category,
      is_owned,
      agency_id,
      is_resolved,
      onClickCategoryDefinitions,
      onClickDelete,
      onClickHide,
      onClickReport,
      onClickReSchedule,
      onClickEditScheduledPost,
      onClickResolve: () => {
        resolveModalOptions.onClick();
        if (handleResolvePostClick) handleResolvePostClick();
      },
    });

    return (
      <Card {...props} ref={ref}>
        {children({ dropdownOptions, resolveModalOptions })}
      </Card>
    );
  },
);

EventCardBase.displayName = 'EventCardBase';

type EventCardCommonProps = Pick<
  EventCardProviderProps,
  'eventAreaCenter' | 'event'
>;

export interface EventCardProps
  extends Omit<
      EventCardBaseProps,
      | 'onClickCategoryDefinitions'
      | 'onClickHide'
      | 'onClickDelete'
      | 'onClickReport'
    >,
    EventCardCommonProps {
  onDelete: () => void;
  onHide: () => void;
  openReportModal: () => void;
  handleResolvePostClick?: () => void;
  handleReSchedulePostClick?: (id: string, scheduledFor: string) => void;
  handleEditScheduledPostClick?: (
    id: string,
    title: string,
    description: string,
  ) => void;
}

export const EventCard = forwardRef<HTMLDivElement, EventCardProps>(
  (
    {
      event,
      eventAreaCenter,
      children,
      onHide,
      onDelete,
      openReportModal,
      handleResolvePostClick,
      handleReSchedulePostClick,
      handleEditScheduledPostClick,
      ...props
    },
    ref,
  ) => {
    const [hideModalIsOpen, toggleHideModalIsOpen] = useToggle(false);
    const [deleteModalIsOpen, toggleDeleteModalIsOpen] = useToggle(false);
    const [categoryModalIsOpen, toggleCategoryModalIsOpen] = useToggle(false);
    const [reScheduleModalIsOpen, toggleReScheduleModalIsOpen] =
      useToggle(false);
    const [editScheduledPostIsOpen, toggleEditScheduledPostIsOpen] =
      useToggle(false);
    const { t } = useTranslation('components.modal');

    const history = useHistory();
    const pageName = getViewFromRoutePath(history.location.pathname);

    const dispatchClick = useNeighborsWebClickEventStreamDispatcher(pageName);

    const handleHide = useCallback(() => {
      if (dispatchClick) {
        dispatchClick('nhWeb_hidePostWeb', ['Neighbors Web'], {});
      }
      toggleHideModalIsOpen();
      onHide();
      trackEventWithHeap('Hide Post Confirm Click');
    }, [dispatchClick, onHide, toggleHideModalIsOpen]);

    const handleDelete = useCallback(() => {
      if (dispatchClick) {
        dispatchClick('nhWeb_deletePostWeb', ['Neighbors Web'], {});
      }
      toggleDeleteModalIsOpen();
      onDelete();
    }, [dispatchClick, toggleDeleteModalIsOpen, onDelete]);

    const handleCategoryDefClick = () => {
      if (dispatchClick) {
        dispatchClick('nhWeb_categoryDefinitionsWeb', ['Neighbors Web'], {});
      }
      toggleCategoryModalIsOpen();
    };

    return (
      <EventCardProvider event={event} eventAreaCenter={eventAreaCenter}>
        <EventCardBase
          {...props}
          ref={ref}
          onClickCategoryDefinitions={handleCategoryDefClick}
          onClickHide={toggleHideModalIsOpen}
          onClickDelete={toggleDeleteModalIsOpen}
          onClickReport={openReportModal}
          onClickReSchedule={toggleReScheduleModalIsOpen}
          onClickEditScheduledPost={toggleEditScheduledPostIsOpen}
          handleResolvePostClick={handleResolvePostClick}
          handleEditScheduledPostClick={handleEditScheduledPostClick}
        >
          {children}
        </EventCardBase>

        <ConfirmationModal
          isOpen={hideModalIsOpen}
          confirmationText={t('hide-modal.confirmation-text')!}
          headerText={t('hide-modal.header')!}
          onClose={toggleHideModalIsOpen}
          onSubmit={handleHide}
        />

        <ConfirmationModal
          confirmationText={t('delete-modal.confirmation-text')!}
          headerText={t('delete-modal.header')!}
          isOpen={deleteModalIsOpen}
          onClose={toggleDeleteModalIsOpen}
          onSubmit={handleDelete}
        />

        {event.details?.category === 'scheduled' && (
          <>
            <EventCardReScheduleModal
              onSubmit={handleReSchedulePostClick!}
              isOpen={reScheduleModalIsOpen}
              onClose={toggleReScheduleModalIsOpen}
              event={event as ScheduledEvent}
            />

            <EventCardUpdateScheduledPostModal
              onSubmit={handleEditScheduledPostClick!}
              event={event as ScheduledEvent}
              isOpen={editScheduledPostIsOpen}
              onClose={toggleEditScheduledPostIsOpen}
            />
          </>
        )}

        <IncidentCategoriesModal
          isOpen={categoryModalIsOpen}
          onClose={toggleCategoryModalIsOpen}
        />
      </EventCardProvider>
    );
  },
);

EventCard.displayName = 'EventCard';

export interface EventCardOpenProps
  extends Omit<
      EventCardBaseProps,
      | 'onClickCategoryDefinitions'
      | 'onClickHide'
      | 'onClickDelete'
      | 'onClickReport'
    >,
    EventCardCommonProps {
  openCTAModal: () => void;
}

export const EventCardOpen = forwardRef<HTMLDivElement, EventCardOpenProps>(
  ({ event, eventAreaCenter, children, openCTAModal, ...props }, ref) => {
    const handleClickCategoryDefinitions = () => {
      openCTAModal();
      trackEventWithHeap('CTA Modal - Open', {
        trigger: 'Category Definitions click',
      });
    };

    const handleClickHide = () => {
      openCTAModal();
      trackEventWithHeap('CTA Modal - Open', { trigger: 'Hide Post click' });
    };

    const handleClickReport = () => {
      openCTAModal();
      trackEventWithHeap('CTA Modal - Open', { trigger: 'Report Post click' });
    };

    return (
      <EventCardProvider event={event} eventAreaCenter={eventAreaCenter}>
        <EventCardBase
          {...props}
          onClickCategoryDefinitions={handleClickCategoryDefinitions}
          onClickHide={handleClickHide}
          onClickReport={handleClickReport}
          ref={ref}
        >
          {children}
        </EventCardBase>
      </EventCardProvider>
    );
  },
);

EventCardOpen.displayName = 'EventCardOpen';
