import { Heap } from 'src/types/window';

export const addEventProperties = (
  authenticated: boolean,
  isNpssUser: boolean,
) => {
  window.heap?.addEventProperties({
    Authenticated: authenticated,
    IsNpssUser: isNpssUser,
  });
};

export const trackEventWithHeap: Heap['track'] = (
  e: string,
  properties?: Object,
) => {
  if (!window.heap || !window.heap.loaded) return;
  window.heap.track(e, properties);
};
